
import React from 'react';
import Header from './common/Header';
import ContentIndex from './index/Content';
import About from './About';
import Services from './Services';
import Events from './Events';
import Footer from './common/Footer';
import PhotoGallery from './PhotoGallery';
import VideoGallery from './VideoGallery';
import ParentalEducation from './ParentalEducation';
import BlogSingle from './BlogSingle';
import ClientFeedback from './ClientFeedback';
import ContactUs from './ContactUs';
import JobVacancies from './JobVacancies';
import BookAppointment from './BookAppointment';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faCoffee, faMobileScreenButton, faLocationDot, faEnvelope, faUserDoctor, faHeadSideCough, faBookMedical, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

library.add( faCheckSquare, faCoffee, faMobileScreenButton, faLocationDot, faEnvelope, faUserDoctor, faHeadSideCough, faBookMedical, faAngleRight )
function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header /> 
        <Switch>
          <Route path='/about' component={About}/>
          <Route path='/services' component={Services}/>
          <Route path='/events' component={Events}/>
          <Route path='/photo-gallery' component={PhotoGallery}/>
          <Route path='/video-gallery' component={VideoGallery}/>
          <Route path='/parental-education' component={ParentalEducation}/>
          <Route path='/blog-single' component={BlogSingle}/>
          <Route path='/client-feedback' component={ClientFeedback}/>
          <Route path='/contact' component={ContactUs}/>
          <Route path='/job-vacancies' component={JobVacancies}/>
          <Route path='/book-appointment' component={BookAppointment}/>
          <Route path='/' component={ContentIndex}/>
        </Switch>
        <Footer />
      </div>
      </BrowserRouter>
  );
}

export default App;
