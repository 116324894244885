import React, { Component } from 'react';
import slider1 from './images/homeBan3.jpg';
import { Button } from 'react-bootstrap';

export default class VideoGallery extends Component {
  render() {
    return(
      <div className='phoGalPag'>
        {/* banner */}
        <div className="innBanner" style={{ backgroundImage:`url(${slider1})` }}>
          <h2>Video Gallery</h2>
          <Button variant="primary" size="lg" href="/book-appointment">Online Appoinment</Button>
        </div>
        <div className='container pgpVid'>
          <div className='row'>
            <div className='col-md-6 col-lg-4'>
              <div className='youtube16'>
                <iframe src="https://www.youtube.com/embed/d0fA1lZP9Ks?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
            <div className='col-md-6 col-lg-4'>
              <div className='youtube16'>
                <iframe src="https://www.youtube.com/embed/k-P6IMlTeaM?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
            <div className='col-md-6 col-lg-4'>
              <div className='youtube16'>
                <iframe src="https://www.youtube.com/embed/d0fA1lZP9Ks?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
            <div className='col-md-6 col-lg-4'>
              <div className='youtube16'>
                <iframe src="https://www.youtube.com/embed/k-P6IMlTeaM?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}