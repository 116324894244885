import React, { Component } from 'react';
import slider1 from './images/aboutBan.jpg';
import gal1 from './images/gal1.jpg';
import gal2 from './images/gal2.jpg';
import gal3 from './images/gal3.jpg';
import gal4 from './images/gal4.jpg';
import gal5 from './images/gal5.jpg';
import gal6 from './images/gal6.jpg';
import gal7 from './images/gal7.jpg';
import gal8 from './images/gal8.jpg';
import gal9 from './images/gal9.jpg';
import { Button } from 'react-bootstrap';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

export default class PhotoGallery extends Component {
  render() {
    return(
      <div className='phoGalPag'>
        {/* banner */}
        <div className="innBanner" style={{ backgroundImage:`url(${slider1})` }}>
          <h2>Photo Gallery</h2>
          <Button variant="primary" size="lg" href="/book-appointment">Online Appoinment</Button>
        </div>
        <div className='container'>
            <ResponsiveMasonry
              columnsCountBreakPoints={{300:1, 550: 2, 767: 3, 991: 4}}
            >
              <Masonry>
                <div className='pgpGal'>
                  <img src={gal1} alt="gallery" />
                </div>
                <div className='pgpGal'>
                  <img src={gal2} alt="gallery" />
                </div>
                <div className='pgpGal'>
                  <img src={gal3} alt="gallery" />
                </div>
                <div className='pgpGal'>
                  <img src={gal4} alt="gallery" />
                </div>
                <div className='pgpGal'>
                  <img src={gal5} alt="gallery" />
                </div>
                <div className='pgpGal'>
                  <img src={gal6} alt="gallery" />
                </div>
                <div className='pgpGal'>
                  <img src={gal7} alt="gallery" />
                </div>
                <div className='pgpGal'>
                  <img src={gal8} alt="gallery" />
                </div>
                <div className='pgpGal'>
                  <img src={gal9} alt="gallery" />
                </div>
              </Masonry>
            </ResponsiveMasonry>
        </div>
      </div>
    );
  }
}