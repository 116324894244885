import React, { Component } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import eve1 from './images/art1.PNG';
import eve2 from './images/art2.jpg';
import slider1 from './images/homeBan3.jpg';
import slider2 from './images/Certificate-course-on-Autism-in-association-with-Adelphi-University-USA_6.jpg';
import slider3 from './images/gallery_20.jpg';
import slider4 from './images/rosalin-award.jpg';
import { Button } from 'react-bootstrap';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

export default class Events extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true
    };
    return(
      <div className='evePagCon'>
        {/* banner */}
        <div className="innBanner" style={{ backgroundImage:`url(${slider1})` }}>
          <h2>Events</h2>
          <Button variant="primary" size="lg" href="/book-appointment">Online Appoinment</Button>
        </div>
        <div className='container'>
            <ResponsiveMasonry
              columnsCountBreakPoints={{350: 1, 767: 2, 991: 3}}
            >
              <Masonry>
                <div className='epcEve'>
                  <h5 className='ttlSec'>On the occasion of “World Autism Awareness” day, my article has been published in Hindustan Newspaper.</h5>
                  <Slider {...settings} className="">
                    <div>
                      <img src={eve1} />
                    </div>
                    <div>
                      <img src={eve2} />
                    </div>
                  </Slider>
                  <p>On the occasion of “World Autism Awareness” day, my article has been published in Hindustan Newspaper.</p>
                </div>
                <div className='epcEve'>
                  <h5 className='ttlSec'>Positive Parenting session at “Asian Kids School”,at “Asian Kids School”, Lucknow Lucknowat “Asian Kids School”, Lucknow</h5>
                  <Slider {...settings} className="">
                    <div>
                      <img src={slider1} />
                    </div>
                    <div>
                      <img src={slider2} />
                    </div>
                    <div>
                      <img src={slider3} />
                    </div>
                    <div>
                      <img src={slider4} />
                    </div>
                  </Slider>
                  <p>Dr. Rosalin Nath, invited for. The sessions were fruitful for both teachers as well as parents. It was all about how to early identify & manage the kids and how positive parenting play important role in it.</p>
                </div>
                <div className='epcEve'>
                  <h5 className='ttlSec'>Positive Parenting session at “Asian Kids School”, Lucknow</h5>
                  <Slider {...settings} className="">
                    <div>
                      <img src={slider1} />
                    </div>
                    <div>
                      <img src={slider2} />
                    </div>
                    <div>
                      <img src={slider3} />
                    </div>
                    <div>
                      <img src={slider4} />
                    </div>
                  </Slider>
                  <p>Dr. Rosalin Nath, invited for conducting sessions on Positive Parenting at Asian Kids School. The sessions were fruitful for both teachers as well as parents. It was all about how to early identify & manage the kids and how positive parenting play important role in it.</p>
                </div>
                <div className='epcEve'>
                  <h5 className='ttlSec'>Positive Parenting session at “Asian Kids School”, Lucknow</h5>
                  <Slider {...settings} className="">
                    <div>
                      <img src={slider1} />
                    </div>
                    <div>
                      <img src={slider2} />
                    </div>
                    <div>
                      <img src={slider3} />
                    </div>
                    <div>
                      <img src={slider4} />
                    </div>
                  </Slider>
                  <p>Dr. Rosalin Nath, invited for conducting sessions on Positive Parenting at Asian Kids School. The sessions were fruitful for both teachers as well as parents. It was all about how to early identify & manage the kids and how positive parenting play important role in it.</p>
                </div>
                <div className='epcEve'>
                  <h5 className='ttlSec'>Positive Parenting session at “Asian Kids School”, Lucknow</h5>
                  <Slider {...settings} className="">
                    <div>
                      <img src={slider1} />
                    </div>
                    <div>
                      <img src={slider2} />
                    </div>
                    <div>
                      <img src={slider3} />
                    </div>
                    <div>
                      <img src={slider4} />
                    </div>
                  </Slider>
                  <p>Dr. Rosalin Nath, invited for conducting sessions on Positive Parenting at Asian Kids School. The sessions were fruitful for both teachers as well as parents. It was all about how to early identify & manage the kids and how positive parenting play important role in it.</p>
                </div>
              </Masonry>
            </ResponsiveMasonry>
        </div>
      </div>
    );
  }
}