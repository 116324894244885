import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import logo from '../images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const header = () => {
    return(
      <div>
        <div className='topBar'>
          <Container>
            <p className='tbAdd'><FontAwesomeIcon icon="location-dot" /> D-1/106, Vibhav Khand, Gomti Nagar, Lucknow, Uttar Pradesh 226010</p>
            <p><FontAwesomeIcon icon="mobile-screen-button" /> 8177023887, 7275504855</p>
            <p><a href='mailto:enquiry@rosalinchilddevelopment.com'><FontAwesomeIcon icon="envelope" /> enquiry@rosalinchilddevelopment.com</a></p>
            {/* <h5> ISO 9001-2015 Certified Organisation</h5> */}
          </Container>
        </div>
      <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} alt="Site logo"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown title="About" renderMenuOnMount={true}>
              <NavDropdown.Item href='/about'>About Us</NavDropdown.Item>
              <NavDropdown.Item href="/services">Services</NavDropdown.Item>
              <NavDropdown.Item href="/contact">Contact Us</NavDropdown.Item>
              <NavDropdown.Item href="/job-vacancies">Job Vacancies</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/events">Events</Nav.Link>
            <NavDropdown title="Our Gallery" renderMenuOnMount={true}>
              <NavDropdown.Item href="/photo-gallery">Photo Gallery</NavDropdown.Item>
              <NavDropdown.Item href="/video-gallery">Video Gallery</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/parental-education">Parental Education</Nav.Link>
            <Nav.Link href="/client-feedback">Client Feedback</Nav.Link>
          </Nav>
          <div className='nbRight'>
            <h4 className='mb-0'>FB like & share</h4>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
    );
}

export default header;