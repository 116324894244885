import React, { Component } from 'react';
import slider1 from './images/homeBan1.jpg';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class BlogSingle extends Component {
  render() {
    return(
      <div className='parEduPag'>
        {/* banner */}
        <div className="innBanner" style={{ backgroundImage:`url(${slider1})` }}>
          <h2>Parental Education</h2>
          <h5>Rosalin Child Development and Rehabilitation Centre</h5>
          <Button variant="primary" size="lg" href="/book-appointment">Online Appoinment</Button>
        </div> 
        <div className='pepMain'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-8'>
                <div className='pmCon pmConInn'>
                  <h5 className='ttlSec'>Facts about Autism</h5>
                  <p>Autism is the fastest-growing developmental disability in the United States/World. Boys are nearly five times more likely than girls to have autism. April is World Autism Month Half of children identified with an Autism Spectrum Disorder have average or above- average intelligence. In 2007, the United Nations declared April 2 nd as World Autism Awareness</p>
                  <ul className='listFontawesome'>
                    <li><FontAwesomeIcon icon="angle-right" /> Autism is the fastest-growing developmental disability in the United States/World.</li>
                    <li><FontAwesomeIcon icon="angle-right" /> Boys are nearly five times more likely than girls to have autism.</li>
                    <li><FontAwesomeIcon icon="angle-right" /> April is World Autism Month</li>
                    <li><FontAwesomeIcon icon="angle-right" /> Half of children identified with an Autism Spectrum Disorder have average or above-average intelligence.</li>
                    <li><FontAwesomeIcon icon="angle-right" /> In 2007, the United Nations declared April 2nd as World Autism Awareness Day.</li>
                    <li><FontAwesomeIcon icon="angle-right" /> As part of April 2nd, Autism Speaks started the global autism awareness initiative called Light It Up Blue.</li>
                    <li><FontAwesomeIcon icon="angle-right" /> There is currently no medical test to detect autism or a cure.</li>
                    <li><FontAwesomeIcon icon="angle-right" /> About 25 percent of individuals with autism are nonverbal</li>
                    <li><FontAwesomeIcon icon="angle-right" /> Dr. Leo Kanner was the first to describe autism in 1943.</li>
                    <li><FontAwesomeIcon icon="angle-right" /> There are five types of autism-spectrum disorders: Pervasive Developmental Disorder- Not Otherwise Specified, Autism, Asperger Syndrome, Rett Syndrome and Childhood Disintegrative Disorder.</li>
                    <li><FontAwesomeIcon icon="angle-right" /> You can see the signs of autism in infants as young as six months</li>
                    <li><FontAwesomeIcon icon="angle-right" /> About 1% of the world population has autism.</li>
                    <li><FontAwesomeIcon icon="angle-right" /> Individuals with autism who need therapy should be seen on a case-by-case basis. Although some therapies are debated today some of the most universal therapies that are helping today are Occupational and Speech therapy.</li>
                    <li><FontAwesomeIcon icon="angle-right" /> Other therapies currently suggested to help individuals with autism include: Music therapy, Visual Schedules, Sensory Integration therapy, and Cognitive-Behavioral Therapy.</li>
                    <li><FontAwesomeIcon icon="angle-right" /> Although therapies are pivotal for individuals with autism, other things such as a healthy diet and daily exercise are seen as ways to help them progress.</li>
                    <li><FontAwesomeIcon icon="angle-right" /> Autism is NOT caused by bad parenting. But symptoms aggravate by bad parenting.</li>
                    <li><FontAwesomeIcon icon="angle-right" /> 1 in 158, individuals are currently diagnosed with autism in the India.</li>
                  </ul>
                </div>
              </div>
              <div className='col-lg-4'>
                <h5 className='ttl'>Our Recent Blogs</h5>
                <div className='pmRecBlo'>
                  <a href='#'>
                    Facts about Autism
                  </a>
                  <a href='#'>
                    Facts About Cerebral Palsy
                  </a>
                  <a href='#'>
                    Facts about ADHD/Hyperactivity
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}