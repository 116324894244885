import React, { Component } from 'react';
import slider1 from './images/homeBan2.jpg';
import { Button } from 'react-bootstrap';
import Testimonials from './index/Testimonials'

export default class ClientFeedback extends Component {
  render() {
    return(
      <div className='cliFedPag'>
        {/* banner */}
        <div className="innBanner" style={{ backgroundImage:`url(${slider1})` }}>
          <h2>Client Feedback</h2>
          <Button variant="primary" size="lg" href="/book-appointment">Online Appoinment</Button>
        </div>
        {/* testimonials */}
        <div className='tesSlick'>
          <h2 className='ttlMain'>What our <span>Clients</span> are saying</h2>
          <div className='container'>
            <Testimonials />
          </div>
        </div>
        {/* Videos */}
        <div className='container cfpVid'>
          <div className='row'>
            <div className='col-md-6 col-lg-4'>
              <h5 className='ttlSec'>Facts about Autism</h5>
              <div className='youtube16'>
                <iframe src="https://www.youtube.com/embed/d0fA1lZP9Ks?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
            <div className='col-md-6 col-lg-4'>
              <h5 className='ttlSec'>Parents Review</h5>
              <div className='youtube16'>
                <iframe src="https://www.youtube.com/embed/k-P6IMlTeaM?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
            <div className='col-md-6 col-lg-4'>
              <h5 className='ttlSec'>Facts about Autism</h5>
              <div className='youtube16'>
                <iframe src="https://www.youtube.com/embed/d0fA1lZP9Ks?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
            <div className='col-md-6 col-lg-4'>
              <h5 className='ttlSec'>Parents Review</h5>
              <div className='youtube16'>
                <iframe src="https://www.youtube.com/embed/k-P6IMlTeaM?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}