import React, { Component } from 'react';
import slider1 from './images/homeBan1.jpg';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'

export default class JobVacancies extends Component {
  render() {
    return(
      <div className='conUsPag jobVacPag'>
        {/* banner */}
        <div className="innBanner" style={{ backgroundImage:`url(${slider1})` }}>
          <h2>Job Vacancies</h2>
          <Button variant="primary" size="lg" href="/book-appointment">Online Appoinment</Button>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <h5 className='ttl'>Current Openings</h5>
              <div className='jvpOpe'>
                <h5>Special Educator</h5>
                <p><b>No of Vacancies : 1</b></p>
                <p><b>Description :</b> Urgent requirement Special Educator from Lucknow</p>
              </div>
              <div className='jvpOpe'>
                <h5>Physiotherapist</h5>
                <p><b>No of Vacancies : 1</b></p>
                <p><b>Description :</b> Required Physiotherapist from Lucknow</p>
              </div>
              <div className='jvpOpe'>
                <h5>Occupational Therapist</h5>
                <p><b>No of Vacancies : 1</b></p>
                <p><b>Description :</b> Required Occupational Therapist</p>
              </div>
              <div className='jvpOpe'>
                <h5>Speech Therapist</h5>
                <p><b>No of Vacancies : 2</b></p>
                <p><b>Description :</b> Urgent requirement of Speech Therapist</p>
              </div>
              <div className='jvpOpe'>
                <h5>Psychologist</h5>
                <p><b>No of Vacancies : 1</b></p>
                <p><b>Description :</b> Urgent requirement of Psychologist</p>
              </div>
              <div className='jvpOpe'>
                <h5>Receptionist</h5>
                <p><b>No of Vacancies : 1</b></p>
                <p><b>Description :</b> Urgent requirement of Receptionist</p>
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='cupForm'>
                <h5 className='ttl'>Share Your CV</h5>
                <Form>
                  <div className='row'>
                    <div className='col-md-6'>
                      <Form.Control type="text" placeholder="Enter your Name" />
                    </div>
                    <div className='col-md-6'>
                      <Form.Control type="email" placeholder="Enter email ID" />
                    </div>
                    <div className='col-md-6'>
                      <Form.Control type="Number" placeholder="Enter Mobile Number" />
                    </div>
                    <div className='col-md-6'>
                      <Form.Control type="Number" placeholder="Enter WhatsApp Number" />
                    </div>
                    <div className='col-md-6'>
                      <Form.Control type="file" />
                    </div>
                    <div className='col-md-6'>
                      <Form.Select aria-label="Default select example">
                        <option>Applying For</option>
                        <option value="1">Special Educator</option>
                        <option value="2">Physiotherapis</option>
                        <option value="3">Occupational Therapist</option>
                        <option value="4">Speech Therapist</option>
                      </Form.Select>
                    </div>
                    <div className='col-md-12'>
                      <Form.Control as="textarea" rows={3} placeholder="Your Message *" />
                    </div>
                    <div className='col-12'>
                      <Button variant="primary" type="submit">
                        Submit Resume
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}