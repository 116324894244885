import logo from '../images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const footer = () => {
  return(
    <div>
      <footer>
        <div className='container'>
          <div className='row'>
            <div className='col-md-3'>
              <div className='fotAbout'>
                <img src={logo} />
              </div>
            </div>
            <div className='col-12 showMobile'>
              <div className='fotLinks'>
                <a href='https://www.rosalinchilddevelopment.com'>Home</a>
                <a href='https://www.rosalinchilddevelopment.com/about-us'>About Us</a>
                <a href='https://www.rosalinchilddevelopment.com/contact-us'>Contact Us</a>
                <a href='https://www.rosalinchilddevelopment.com/careers'>Job Vacancies</a>
                <a href='#'>Portfolio</a>
                <a href='#'>Privacy Policy</a>
                <a href='#'>Terms & Conditions</a>
                <a href='https://www.rosalinchilddevelopment.com/book-appointment'>Book Appointment</a>
                <a href='https://www.rosalinchilddevelopment.com/services'>Services</a>
                <a href='https://www.rosalinchilddevelopment.com/site-map'>Sitemap</a>
                <a href='https://www.rosalinchilddevelopment.com/events'>Events</a>
                <a href='https://www.rosalinchilddevelopment.com/photo-gallery'>Photo Gallery</a>
                <a href='https://www.rosalinchilddevelopment.com/video-gallery'>Video Gallery</a>
                <a href='https://www.rosalinchilddevelopment.com/parental-education'>Parental Education</a>
                <a href='https://www.rosalinchilddevelopment.com/client-feedback'>Client Feedback</a>
              </div>
            </div>
            <div className='col-sm-4 col-md-3 hideMobile'>
              <div className='fotLinks'>
                <a href='https://www.rosalinchilddevelopment.com'>Home</a>
                <a href='https://www.rosalinchilddevelopment.com/about-us'>About Us</a>
                <a href='https://www.rosalinchilddevelopment.com/contact-us'>Contact Us</a>
                <a href='https://www.rosalinchilddevelopment.com/careers'>Job Vacancies</a>
                <a href='#'>Portfolio</a>
              </div>
            </div>
            <div className='col-sm-4 col-md-3 hideMobile'>
              <div className='fotLinks'>
                <a href='#'>Privacy Policy</a>
                <a href='#'>Terms & Conditions</a>
                <a href='https://www.rosalinchilddevelopment.com/book-appointment'>Book Appointment</a>
                <a href='https://www.rosalinchilddevelopment.com/services'>Services</a>
                <a href='https://www.rosalinchilddevelopment.com/site-map'>Sitemap</a>
              </div>
            </div>
            <div className='col-sm-4 col-md-3 hideMobile'>
              <div className='fotLinks'>
                <a href='https://www.rosalinchilddevelopment.com/events'>Events</a>
                <a href='https://www.rosalinchilddevelopment.com/photo-gallery'>Photo Gallery</a>
                <a href='https://www.rosalinchilddevelopment.com/video-gallery'>Video Gallery</a>
                <a href='https://www.rosalinchilddevelopment.com/parental-education'>Parental Education</a>
                <a href='https://www.rosalinchilddevelopment.com/client-feedback'>Client Feedback</a>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='fotCon'>
                <p className='tbAdd'><FontAwesomeIcon icon="location-dot" /> D-1/106, Vibhav Khand, Gomti Nagar, Lucknow, Uttar Pradesh 226010</p>
                <p><FontAwesomeIcon icon="mobile-screen-button" /> 8177023887, 7275504855</p>
                <p><a href='mailto:enquiry@rosalinchilddevelopment.com'><FontAwesomeIcon icon="envelope" /> enquiry@rosalinchilddevelopment.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className='copyRig'>
        <div className='container'>
          <p>© 2017 - 2022, Rosalin Child Development And Rehabilitation Centre. All Rights Reserved</p>
          <p>Developed By <a href=''>Webniter</a></p>
        </div>
      </div>
    </div>
  );
}

export default footer;