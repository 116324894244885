import React, { Component } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import slider1 from '../images/homeBan1.jpg';
import slider2 from '../images/homeBan2.jpg';
import slider3 from '../images/homeBan3.jpg';
import slider4 from '../images/rosalin-award.jpg';
import { Button } from 'react-bootstrap';

export default class mainBanner extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      autoplay:true
    };
    return (
        <Slider {...settings} className="mainBanner">
          <div>
            <div className='mbBgIma' style={{ backgroundImage:`url(${slider1})` }}>
              <div className='mbiCon'>
                <h2>Rosalin Child Development Centre</h2>
                <h5>ISO 9001-2015 Certified Organisation</h5>
                <div className='mbiBtns'>
                  <Button variant="primary" size="lg" href="/book-appointment">Online Appoinment</Button>
                </div>
              </div> 
            </div>
          </div>
          <div>
            <div className='mbBgIma' style={{ backgroundImage:`url(${slider2})` }}>
              <div className='mbiCon'>
                <h2>Rosalin Child Development Centre</h2>
                <div className='mbiBtns'>
                  <Button variant="primary" size="lg" href="/book-appointment">Online Appoinment</Button>
                </div>
              </div> 
            </div>
          </div>
          <div>
            <div className='mbBgIma' style={{ backgroundImage:`url(${slider3})` }}>
              <div className='mbiCon'>
                <h2>Rosalin Child Development Centre</h2>
                <div className='mbiBtns'>
                  <Button variant="primary" size="lg" href="/book-appointment">Online Appoinment</Button>
                </div>
              </div> 
            </div>
          </div>
          <div>
            <div className='mbBgIma' style={{ backgroundImage:`url(${slider4})` }}>
              <div className='mbiCon'>
                <h2>Rosalin Child Development Centre</h2>
                <div className='mbiBtns'>
                  <Button variant="primary" size="lg" href="/book-appointment">Online Appoinment</Button>
                </div>
              </div> 
            </div>
          </div>
        </Slider>
    );
  }
}