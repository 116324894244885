import React, { Component } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import slider1 from '../images/slider02.jpg';
import slider2 from '../images/Certificate-course-on-Autism-in-association-with-Adelphi-University-USA_6.jpg';
import slider3 from '../images/gallery_20.jpg';
import slider4 from '../images/rosalin-award.jpg';

export default class testimonials extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true
    };
    return (
      <Slider {...settings} className="testimanialSli">
        <div>
          <div className='tsSlider'>
            <img src={slider1} />
            <div className='mbiCon'>
              <h4 className='ttlSec'>Autism</h4>
              <p>Half of my life is devoted to Rosalin Mam's Team. My child IBAAD (2.5 Yrs) has gained lots of positive changes in last 3 monhts. His human interaction improved, he has improved irritative behaviors. We as parents noticed improvements in his basic understanding.</p>
              <p>We as parents believe that our baby will gain more & soon he will be perfect. In our opinion this is the best centre of ASD. I am recommending all parents/kids, who has these problems to join this Center. 'A Ray of Hope'</p>
              <p>IBAD's Parents</p>
            </div> 
          </div>
        </div>
        <div>
          <div className='tsSlider'>
            <img src={slider2} />
            <div className='mbiCon'>
              <h4 className='ttlSec'>Speech Initiation in Autism</h4>
              <p>She doing well in speech now. Earlier she didn't Co-Operate in learning any word. Now she calls MA... MA... PA... PA... DA... DA..., etc. She now pointing lips and nose. Developing social smile, Playing with toys.</p>
            </div> 
          </div>
        </div>
        <div>
          <div className='tsSlider'>
            <img src={slider3} />
            <div className='mbiCon'>
              <h4 className='ttlSec'>Speech Development in Autism</h4>
            </div> 
          </div>
        </div>
        <div>
          <div className='tsSlider'>
            <img src={slider4} />
            <div className='mbiCon'>
              <h4 className='ttlSec'>Autism Treatment</h4>
            </div> 
          </div>
        </div>
      </Slider>
    );
  }
}