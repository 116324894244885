import React, { Component } from 'react';
import slider1 from './images/homeBan3.jpg';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import googleMap from './images/googleMap.png'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

export default class BookAppointment extends Component {
  render() {
    return(
      <div className='conUsPag bokAppPag'>
        {/* banner */}
        <div className="innBanner" style={{ backgroundImage:`url(${slider1})` }}>
          <h2>Book Appointment</h2>
          <Button variant="primary" size="lg" href="/">Home</Button>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='cupForm'>
                <h5 className='ttl'>Online Appointment</h5>
                <Form>
                  <h5 className='ttlSec'>Patient Details</h5>
                  <div className='row'>
                    <div className='col-md-6 col-lg-4'>
                      <Form.Control type="text" placeholder="Patient Name" />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                      <Form.Control type="Number" placeholder="Mobile Number" />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                      <Form.Control type="email" placeholder="Email-ID" />
                    </div>
                  </div>
                  <h5 className='ttlSec'>Date & Time</h5>
                  <div className='row'>
                    <div className='col-md-6 col-lg-4'>Datepicker here</div>
                    <div className='col-md-6 col-lg-4'>Time slot here</div>
                    <div className='mb-4'></div>
                  </div>
                  <h5 className='ttlSec'>Case Details</h5>
                  <div className='row'>
                    <div className='col-md-8'>
                      <Form.Control type="text" placeholder="Case" />
                      <Form.Control as="textarea" rows={3} placeholder="Remarks" />
                    </div>
                    <div className='col-md-4'>
                      Capcha Here
                    </div>
                  </div>
                  <Button variant="primary" type="submit">
                    Book Appointment
                  </Button>
                </Form>
              </div>
            </div>
            <div className='col-md-12'>
              <h5 className='ttl'>Book Appointment</h5>
              <FullCalendar
                plugins={[ dayGridPlugin ]}
                initialView="dayGridMonth"
              />
            </div>
          </div>
        </div>
        {/* map */}
        <div className='gooMap'>
          <img src={googleMap} alt="Google Map" />
        </div>
      </div>
    );
  }
}