import React, { Component } from 'react';
import slider1 from './images/homeBan1.jpg';
import { Button } from 'react-bootstrap';

export default class ParentalEducation extends Component {
  render() {
    return(
      <div className='parEduPag'>
        {/* banner */}
        <div className="innBanner" style={{ backgroundImage:`url(${slider1})` }}>
          <h2>Parental Education</h2>
          <Button variant="primary" size="lg" href="/book-appointment">Online Appoinment</Button>
        </div> 
        <div className='pepMain'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-8'>
                <div className='pmCon'>
                  <h5 className='ttlSec'>Facts about Autism</h5>
                  <p>Autism is the fastest-growing developmental disability in the United States/World. Boys are nearly five times more likely than girls to have autism. April is World Autism Month Half of children identified with an Autism Spectrum Disorder have average or above- average intelligence. In 2007, the United Nations declared April 2 nd as World Autism Awareness</p>
                  <a href='/blog-single' className='readMore'>Read More</a>
                </div>
                <div className='pmCon'>
                  <h5 className='ttlSec'>Facts About Cerebral Palsy</h5>
                  <p>Cerebral palsy is not a sickness or disease. No one can catch it, or reverse cerebral palsy. It is a brain injury. The injury is usually caused by lack of oxygen to the brain before or after birth. No two people have cerebral palsy the same way. Limitations associated with the condition vary by brain injury location and intensity of the injury. People can walk and still</p>
                  <a href='/blog-single' className='readMore'>Read More</a>
                </div>
                <div className='pmCon'>
                  <h5 className='ttlSec'>Facts about ADHD/Hyperactivity</h5>
                  <p>ADHD is one of the most common conditions in childhood. It impacts how kids function in school and in everyday life. ADHD is caused by differences in the development of brain anatomy and wiring. ADHD isn’t a learning disability, even though it can affect learning. But ADHD often co-occurs with common learning issues like dyslaxa, dyscalculia and</p>
                  <a href='/blog-single' className='readMore'>Read More</a>
                </div>
                <div className='pmCon'>
                  <h5 className='ttlSec'>How you could suspect your child has autism</h5>
                  <p>Here, age wise child’s social behaviours are given, which indicates the deficits in child normal development. No big smiles or other warm, joyful expressions or eye contact by 6 months or thereafter. No back-and-forth sharing of sounds, smiles, or other facial expressions by 9 months or thereafter. No babbling by 12 months. No back-and-forth gestures, such as pointing, showing, reaching, or waving by 12 months</p>
                  <a href='/blog-single' className='readMore'>Read More</a>
                </div>
                <div className='pmCon'>
                  <h5 className='ttlSec'>Facts About Down Syndrome</h5>
                  <p>Down syndrome is not an illness or disease (you can’t catch it), it is a genetic condition caused by the presence of an extra 21st chromosome. People don’t have “mild” Down syndrome, or “severe” Down syndrome. Ability is not dependent on the condition, but rather the individual. People either have Down syndrome or they don’t. Contrary to popular belief, people with Down syndrome are not always happy. They experience every emotion you and I do</p>
                  <a href='#' className='readMore'>Read More</a>
                </div>
              </div>
              <div className='col-lg-4'>
                <h5 className='ttl'>Our Recent Blogs</h5>
                <div className='pmRecBlo'>
                  <a href='/blog-single'>
                    Facts about Autism
                  </a>
                  <a href='/blog-single'>
                    Facts About Cerebral Palsy
                  </a>
                  <a href='/blog-single'>
                    Facts about ADHD/Hyperactivity
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}